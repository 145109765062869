export default {


    data () {
        return {
            isThereMessage: false,
            messageContent: [],
            finalMessageContent: null,
            messageTitle: '',
            messageType: "alert",
            errorRecognized: false,
        }
    },


    methods: {

        displayModaleMessages(title, arrayOfMessages, csq=null, mode='info') {
            this.log("Displaying message by toaster", 'low');
            this.isThereMessage = true;
            this.messageTitle = title;

            if(!Array.isArray(arrayOfMessages)){
                this.log("arrayOfMessages was not an array", 'alert');
                arrayOfMessages = [arrayOfMessages];
            }

            this.messageContent = arrayOfMessages;
            this.finalMessageContent = csq;
            this.messageType = mode;
        },

        hideMessage() {
            this.isThereMessage = false;
        },

        displayUndefinedError(){
            this.log("displayUndefinedError()", 'function');
            this.displayModaleMessages(this.$t('errors.undefinedError'), [this.$t('errors.undefinedErrorPleaseRetry')], this.$t('errors.noDescription'), "alert");
        },

    /* used to retrieve an unknown number of value of table constructed like : [0: ["lorem"], 1: ["ipsum"]] */
        extractErrorsStrings(listErrors){
            let bigArray = [];
            listErrors.forEach(obj => {
                let arrayOfValues = Object.values(obj);
                this.log(arrayOfValues);
                bigArray = [...bigArray, ...arrayOfValues];
            });
            return bigArray;
        },

        transmitErrorFromApi(payload){
            this.log("Just a small conversion", 'low');
            this.iGotThisErrorFromActionApi(payload.error, payload.formMessage);
        },


        // an error has been returned by ACTION_API. Now, I should display a modale with the errors descriptions
        iGotThisErrorFromActionApi(error, formMessage=null){
            this.log("Begin iGotThisErrorFromActionApi()", 'function');
            this.log("I received these data");
            this.log(error);
            this.log(formMessage);

            let arrayOfErrorMessages = [];

            /* Default values */
            let errorTitle = "Erreur/Error/Mistake/Oups";
            let modaleType = "alert";


            // ANY RESPONSE FROM NETWORK NOT FROM ACTION-API
            if(error.response.status >= 500 && error.response.status < 600){
                // special case : at least in prod, we must hide technical details
                this.log(`This is a server error number ${error.response.status}`);
                errorTitle = `Err ${error.response.status} — ${error.response.statusText}`;
                this.log(errorTitle);
                this.log(error.response.data.message);
                arrayOfErrorMessages.push(this.$t('errors.error500'));
                formMessage = this.$t('errors.formUnavailable');
                this.errorRecognized = true;
            }

            // ACTION-API ANSWERS
            // just not found
            else if(error.response.status === 404){
                errorTitle = `Err ${error.response.status} — ${error.response.statusText}`;
                arrayOfErrorMessages.push(error.response.data.message);
                this.errorRecognized = true;
            }

            // Error with the data transmitted
            else if(error.response.status == 422){
                this.log(`This is a servor error number ${error.response.status}`);

                if(error.response.data.code === "PARTICIPATED"){

                    this.log("User has already participated with these identifiers");
                    errorTitle = error.response.data.message;
                    arrayOfErrorMessages.push(error.response.data.notifications);                

                }else{
                    this.log("Other error than 'PARTICIPATED'");
                    this.log(error);

                    try{
                        if(error.response.data.message){
                            errorTitle = error.response.data.message;
                        }
                        if(error.response.data.errors){
                         let temp =  Object.values(error.response.data.errors);
                         arrayOfErrorMessages = this.extractErrorsStrings(temp);
                     }
                 }catch(e){
                    this.log("Error inside errorsManager :/", 'alert');
                    console.log(e);
                }
            }

            this.errorRecognized = true;
        }else{
            this.log("This is not a 422 ERROR");
            this.errorRecognized = false;
        }

        // All analyse done, now, we display the message
        if(this.errorRecognized){
            this.displayModaleMessages(errorTitle, [...arrayOfErrorMessages], formMessage, modaleType);
        }else{
            this.displayUndefinedError();
        }

    },
},
}

